
@font-face {
  font-family: 'Poppins-Thin';
  src: url('../fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Thin.otf')  format('opentype'),
	     url('../fonts/Poppins-Thin.woff') format('woff'), url('../fonts/Poppins-Thin.ttf')  format('truetype'), url('../fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins ExtraLight';
  src: url('../fonts/Poppins ExtraLight.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins ExtraLight.otf')  format('opentype'),
	     url('../fonts/Poppins ExtraLight.woff') format('woff'), url('../fonts/Poppins ExtraLight.ttf')  format('truetype'), url('../fonts/Poppins ExtraLight.svg#Poppins ExtraLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Light.otf')  format('opentype'),
	     url('../fonts/Poppins-Light.woff') format('woff'), url('../fonts/Poppins-Light.ttf')  format('truetype'), url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Regular.otf')  format('opentype'),
	     url('../fonts/Poppins-Regular.woff') format('woff'), url('../fonts/Poppins-Regular.ttf')  format('truetype'), url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/Poppins-SemiBold.woff') format('woff'), url('../fonts/Poppins-SemiBold.ttf')  format('truetype'), url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Medium.otf')  format('opentype'),
	     url('../fonts/Poppins-Medium.woff') format('woff'), url('../fonts/Poppins-Medium.ttf')  format('truetype'), url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Bold.otf')  format('opentype'),
	     url('../fonts/Poppins-Bold.woff') format('woff'), url('../fonts/Poppins-Bold.ttf')  format('truetype'), url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('../fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Thin.otf')  format('opentype'),
	     url('../fonts/Poppins-Thin.woff') format('woff'), url('../fonts/Poppins-Thin.ttf')  format('truetype'), url('../fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

    
@font-face {
  font-family: 'Poppins-Black';
  src: url('../fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Black.otf')  format('opentype'),
	     url('../fonts/Poppins-Black.woff') format('woff'), url('../fonts/Poppins-Black.ttf')  format('truetype'), url('../fonts/Poppins-Black.svg#Poppins-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url('../fonts/Poppins-Italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Italic.otf')  format('opentype'),
	     url('../fonts/Poppins-Italic.woff') format('woff'), url('../fonts/Poppins-Italic.ttf')  format('truetype'), url('../fonts/Poppins-Italic.svg#Poppins-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url('../fonts/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-LightItalic.otf')  format('opentype'),
	     url('../fonts/Poppins-LightItalic.woff') format('woff'), url('../fonts/Poppins-LightItalic.ttf')  format('truetype'), url('../fonts/Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url('../fonts/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-MediumItalic.otf')  format('opentype'),
	     url('../fonts/Poppins-MediumItalic.woff') format('woff'), url('../fonts/Poppins-MediumItalic.ttf')  format('truetype'), url('../fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url('../fonts/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-ThinItalic.otf')  format('opentype'),
	     url('../fonts/Poppins-ThinItalic.woff') format('woff'), url('../fonts/Poppins-ThinItalic.ttf')  format('truetype'), url('../fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
