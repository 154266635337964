
html {
  scroll-behavior: smooth;
}

body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn, .btn-simple, a, .td-name, td{
  -webkit-font-smoothing: antialiased;
}
body {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 1.6;
    font-weight: normal;
    color:#1D1D1B;
    position: relative;
    z-index: 0;
    padding: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
h1, h2, h3, h4, h5 {
    margin: 0 0 1rem;
    line-height: 1.21;
    color: initial;
	font-family: 'Poppins-Bold';
}
a:hover{text-decoration: none;}
p:not(:last-child) {margin-bottom:10px;}
strong, b {
    font-weight: 500;
}
.small, small {
    font-size:100%;
}
img{max-width: 100%;background-size: cover;}
.h2{font-size:38px;}
p{
	color: #1D1D1B;
	font-family: 'Poppins-Regular';
	font-size: 18px;
}
.form-group input::-moz-placeholder {
  color: #1D1D1B80;
  opacity: 1;
}

.form-group input::-ms-input-placeholder {
  color: #1D1D1B80;
}

.form-group input::-webkit-input-placeholder {
  color: #1D1D1B80;
}

.form-group input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-group{position: relative;}
.form-group input
{
    background-color: #EDF0F3;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    height: 54px;
    padding: 0.375rem 1rem;
}
.form-control:focus {
    background-color:#EDF0F3;
    border-color:none;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
form .form-group [type=button]:not(:disabled), form .form-group [type=reset]:not(:disabled), form .form-group [type=submit]:not(:disabled), form .form-group button:not(:disabled){
    cursor: pointer;
	background-color:#E22936;
	color: #fff;
}
button:focus{border: none;outline: none;}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow:none;
}

button.red-btn
{
    background-color: #E22936;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
}
button.red-btn:hover, button.red-btn:focus
{
    background-color: #E22936;
    color: #fff !important;
}
button.border-btn
{
    background-color: transparent;
    border: 1px solid #1D1D1B;
    color: #1D1D1B;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
}
button.border-btn:hover, button.border-btn:focus
{
    background-color: #E22936;
    color: #fff !important;
    border-color: #E22936;
}

/* Mobile Side Menu Css */
.mobile-menu-icon
{
	cursor: pointer;
	font-size: 30px;
	display: none;
}
.mobilesidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top:30px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 10%);
}

.mobilesidenav a {
  text-decoration: none;
  font-size:16px;
  color:#1D1D1B;
  display: block;
  transition: 0.3s;
}

.mobilesidenav .closebtn {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 36px;
    margin-left: 50px;
    padding: 0;
    color: #000;
    font-family: 'Poppins-Light';
}

@media screen and (max-height: 450px) {
  .mobilesidenav {padding-top: 15px;}
  .mobilesidenav a {font-size: 16px;}
}
.mobile-logo{padding:20px 20px;}
.mobilesidenav .navbar-nav li{padding: 14px 20px;}
.mobilesidenav .navbar-nav li a{display: flex;align-items: center;}
.mobilesidenav .navbar-nav li a img
{
	width: 24px;
    margin-right: 12px;
    vertical-align: middle;

}
.mobilesidenav .account-img img{width: 29px !important;}
.mobilesidenav .logout
{
	position: absolute;
    bottom: 0;
    width: 100%;
    padding:24px 20px !important;
    border-top: 1px solid #efeeee;
}

/*-------------- Signin Page Css -----------------*/
/*------------------------------------------------*/

.body-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
	height: 100vh;
	background-color: #E5E6F2;
}
.sat-section {
    padding:5em 0;
}
.sat-logo img{width: 340px;}
.right-white-box
{
	background-color: #fff;
	border-radius: 20px;
	padding:40px 50px;
}
.field-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #1D1D1B80;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;
    border: none;
    width: 36px;
    height: 34px;
}
.forgot-pass{margin-top:50px;}
.forgot-pass a, .red-color{color: #E22936;}
.sat-ls-btn{display: block;padding-top:20px;}
.sat-ls-btn a{
	color: #1D1D1B80;
	border: 1px solid #70707024;
	border-radius: 10px;
	padding: 10px;
	width: 100%;
	display: block;
}
/*.right-white-box #forgotform, .right-white-box #verifyaccount, .right-white-box #resetpassword, .right-white-box #signupform {
    display: none;
}*/
.forgot #logform{
    display: none;
}
.verify #forgotform, .reset #verifyaccount{
    display: none !important;
}
.forgot #forgotform, .verify #verifyaccount, .reset #resetpassword{
    display: block;
}
.signupform #signupform, .resetback #logform{display: block;}
.signupform #logform, .resetback #resetpassword{display: none;}

.verify-icon
{
	text-align: center;
    padding:25px 0;
}

/*--------------------- Dashboard Css -----------------------*/
/*------------------------------------------------------------*/
.navbar-expand-lg .navbar-nav.side-nav{flex-direction: column;}
.header-top{box-shadow: 0 3px 5px rgba(0,0,0,.1); background-color: #fff;}
#wrapper .navbar-collapse
{
	flex-basis: auto;
    display: flex;
}
.navbar-nav.side-nav {
    background: #fff;
    box-shadow: 2px 1px 2px rgb(0 0 0 / 10%);
    position: fixed;
    top: 79px;
    flex-direction: column!important;
    left: 0;
    width: 160px;
    overflow-y: auto;
    bottom: 0;
    overflow-x: hidden;
    height: calc(100vh - 179px);
}
.sidenavtoggler {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin-top: calc(100vh - 100px);
    background-color: #fff;
    border-top: 1px solid #efeeee;
    border-right: 1px solid #efeeee;
    width: 160px;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 1px 2px rgb(0 0 0 / 10%);
}
.sidenavtoggler .nav-item {
    text-align: center;
    color: #1D1D1BBF;
    padding: 28px 10px;
    font-size: 14px;
}
.sidenavtoggler .nav-item a{color: #1D1D1BBF;}

.navbar-nav li, .header-dropdown .dropdown-toggle span, .header-dropdown a{color:#1D1D1B;}

#wrapper .navbar-brand img{width: 210px;}
.main-content{ height: calc(100vh - 180px);}
#map {width: 100%;height: 100%}
.navbar-expand-lg .navbar-nav .nav-link{padding: 0;}
.side-nav .nav-item
{
	text-align: center;
    color: #1D1D1BBF;
    padding: 20px 10px;
	font-size: 14px;
}
.side-nav .nav-item a{color: #1D1D1BBF;}
.btb{
	border-top: 1px solid #efeeee;
	border-bottom: 1px solid #efeeee;
}
.side-nav .nav-item a.active{color: #E22936;}
.device{flex-direction: row;}
.header-dropdown
{
    background-color: #EDF0F3;
    border-radius: 5px;
    font-size: 15px;
    color: #1D1D1B;
    padding: 5px 10px;
}
.header-dropdown .dropdown-toggle::after
{
	margin-left: 10px;
	vertical-align: middle;
	content: '\f107';
	font: normal normal normal 20px/1 FontAwesome;
	border: none;
}
.header-dropdown .dropdown-menu {
    position: absolute;
}


input[type='range'] {
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  height: 35px;
  background: transparent;
  position: relative;
  cursor: pointer;
  background-size: 25% 6px, calc(100% - 5px) 12px;
  background-position: 1px 30%, 1px 20%;
  padding: 0 15px;
  overflow: visible;
  /*******    The Thumb    *******/
}
input[type='range']::-webkit-slider-thumb {
  position: relative;
  height: 8px;
  width: 8px;
  transform: scale(3);
  margin-top: -1px;
  border-radius: 400px;
  background-color: #E22936;
  cursor: pointer;
  z-index: 2;
  -webkit-appearance: none;
  border:2px solid #E8CDCF;
}
input[type='range']::-moz-range-thumb {
  position: relative;
  height: 8px;
  width: 8px;
  transform: scale(3);
  margin-top: -1px;
  border-radius: 400px;
  background-color: #E22936;
  cursor: pointer;
  z-index: 2;
  border:2px solid #E8CDCF;	
}
input[type='range']::-ms-thumb {
  position: relative;
  height: 8px;
  width: 8px;
  transform: scale(3);
  margin-top: -1px;
  border-radius: 400px;
  background-color: #E22936;
  cursor: pointer;
  z-index: 2;
  border:2px solid #E8CDCF;
  /*******    The Track    *******/
}
input[type='range']::-webkit-slider-runnable-track {
  background-color: #E9E9E9;
  height: 7px;
  border-radius: 6px;
}
input[type='range']::-moz-range-track {
  background-color: #E9E9E9;
  height: 7px;
  border-radius: 6px;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background-color: #E9E9E9;
  height: 7px;
  border-radius: 6px;
}
input[type='range']::-ms-fill-upper {
  background-color: #E9E9E9;
  height: 7px;
  border-radius: 6px;
}
input[type='range']:focus {
  outline: none;
}
/*******    The wrapper for the range input    *******/
.range-box {
  position: relative;
  padding: 0;
  margin: 0 35px;
  overflow: visible;
  width: 75%;
  display: flex;
}

/*******    Extra controls on the side    *******/
.no-selection, .control-minus, .control-plus {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.control-minus {
    position: absolute;
    top: 0;
    background-color: #E9E9E9;
    color:#1D1D1B;
    width: 35px;
    text-align: center;
    cursor: pointer;
    height: 35px;
    z-index: 1;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    left: -35px;
    border-radius: 2px;	
}

.control-plus {
  position: absolute;
  top: 0;
  background-color:#E9E9E9;
  color:#1D1D1B;
  width: 35px;
  text-align: center;
  cursor: pointer;
  height: 35px;
  z-index: 1;
  font-weight: bold;
  font-size: 25px;
  line-height: 35px;
  right: -35px;
  border-radius: 2px;
}

/*******    Value tooltip    *******/
.current-value {
    visibility: visible;
    position: absolute;
    top: -20px;
    padding: 0 18px;
    font-size: 16px;
    font-weight: bold;
    color: #1D1D1B;
    line-height: 22px;
    white-space: nowrap;
    transition: left 0.045s linear;
}
.main-content-footer
{
    margin-left: 160px;
    bottom: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 90px;
    background-color: #fff;
}
.dg{
	display: flex;
    align-items: center;
}
#datepicker
{
	width: 180px;
    margin: 0 20px 0 20px;
    background-color: #EDF0F3;
    border: none;
    border-radius: 5px;
}
#datepicker > span:hover{cursor: pointer;}
.input-group-addon {
    padding: 6px 12px;
    text-align: center;
    white-space: nowrap;
}
.input-group-addon i{vertical-align: middle;}
#datepicker .form-control
{
	border: none;
    background-color: transparent;
}
.golive a {
    color: #E22936;
    display: flex;
    font-family: 'Poppins-Medium';
    align-items: center;
}
.golive a:hover {
    color: #E22936;
    font-weight: bold;
    text-decoration:none;
}
.golive a span{margin-left: 3px;}


/*---------------------- Device Page Css ---------------------*/
.main-content-wrapper
{
	padding:25px 40px;
	height: calc(100vh - 90px);
	overflow-y: auto;
    overflow-x: hidden;
}

.device-head{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.device-head .bread-nav{
	font-size: 20px;
	font-family: 'Poppins-Medium';
	color: #1D1D1B;
}
.device-head-right button
{
	background-color: #EDF0F3;
    border-radius: 5px;
    padding: 10px 14px;
	font-family: 'Poppins-Medium';
	font-size: 14px;
}
.device-head-right button span{font-size: 16px; margin-right: 2px;}
.device-table tr th {
    background-color: #F8F8F8;
    font-size: 15px;
    color: #1D1D1BBF;
	font-family: 'Poppins-Light';
}
.device-table thead th, .device-table tbody td {
    border-top: 0;
}
.device-table thead th {
    border-bottom:0;
    padding: 18px 20px;
}
.device-table tbody td {
    padding: 18px 20px;
    font-size: 16px;
    border-bottom: 1px solid #DFDFDF;
	color: #1D1D1B;
}
.main-table
{
    border: 1px solid #DFDFDF;
    margin-top: 14px;
    width: 100%;
    overflow-y: hidden;

}
.icon-tools
{
	display: flex;
	align-items: center;
}
.icon-tools a{padding: 0 8px;}
.modal-content
{
	background-color: #fff;
	border-radius: 20px;
	border: 0;
	padding-bottom: 30px;
}
.modal-header
{
	border-bottom: 0;
    padding: 20px 30px;
}
.modal-header .modal-title
{
	font-size: 24px;
    color: #1D1D1B;
	font-family: 'Poppins-Medium';
}
.modal-body{padding: 0 30px 0;}
.fileupload
{    
	display: flex;
    align-items: center;
    padding: 20px 0 10px;
}
.file-uploader{display: flex;}
.file-upload-btn {
    position: relative;
    overflow: hidden;
    padding: 10px 30px;
    background: transparent;
    border: 1px dashed #7070707A;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    color: #1D1D1B80;
}
.file-upload-btn i {
    margin-right: 5px;
}
.file-upload-btn input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    display: block;
    height: 100%;
    cursor: pointer;
}
.compu_n{color: #1D1D1B80;}
#adddevice .modal-footer
{
	border-top: 0;
    justify-content: center;
}
.device-detail
{
	display: flex;
    align-items: center;
}
.device-detail-left
{
	background-color: #F5F5F5;
    height: 100px;
    width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.device-detail-left img
{
	    width: 70px;
}
.device-detail-right ul{margin-left: 20px;}
.device-detail-right ul li{list-style: none;margin-bottom: 10px;}
.device-detail-right ul li:last-child{margin-bottom: 0;}
.coming-soon h5{font-size: 35px;font-family: 'Poppins-Regular';font-weight: normal;margin-top: 20px;}
.comingsoon-circle
{
    width: 131px;
    height: 131px;
    background-color: #E2293624;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-inner
{
	width: 113px;
    height: 113px;
    background-color: #F8C7CB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-inner img{width: 64px;}
.soon-img{position: absolute;left:12%;bottom: 0;}
.square-box{
    width: 84px;
    height: 84px;
    background-color: #E2293624;
    display: flex;
    justify-content: center;
    align-items: center;
	border-radius: 10px;
}
.logout-icon {
    width:66px;
    height:66px;
    background-color: #F8C7CB;
    display: flex;
    justify-content: center;
    align-items: center;
	border-radius: 10px;
}
.dg .react-date-picker
{
    padding: 0 6px;
    margin: 0 6px 0 14px;
}
.dg .react-date-picker__wrapper
{
    border: none;
    height: 100%;
}
.modal-dialog .modal-content
{
    border: none;
    border-radius: 30px;
}
.modal-dialog .modal-content .modal-header
{
    border-bottom: none;
}
.modal-dialog .modal-content .btn-close
{
    border: none;
    background: transparent;
}
.modal-dialog .modal-content .modal-body
{
    text-align: center;
}
.modal-dialog .modal-content .modal-footer
{
    border: none;
    justify-content: center;
}
.modal-footer .border-btn {
    background-color: transparent;
    border: 1px solid #1D1D1B;
    color: #1D1D1B;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
}
.modal-footer .red-btn {
    background-color: #E22936;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
}
.navbar-nav .form-horizontal
{
    display: flex;
    align-items: center;
}
.golive{width:134px;}
.form-group{text-align: left;}
.device-icon {
    max-width: 50px;
}
@media(min-width:1024px) and (max-width:1300px)
{
    .range-box{width:54%;}
}