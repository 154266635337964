@media(min-width:767px) {
	#wrapper {
		margin-left: 152px;
		padding: 80px 0 0;
	}
}
@media(max-width:1024px) {.soon-img img{width:70%;}}
@media(max-width:991px) {
	.main-content{ height: calc(100vh - 90px);}
	.soon-img img{width:60%;}
}
@media(max-width:767px) {
	.sat-logo {
		text-align: center;
		margin: 15px 0 40px;
	}

	.sat-logo img {
		width: 250px;
	}

	.right-white-box {
		padding: 30px 18px;
	}

	.h2 {
		font-size: 26px;
	}

	.sat-ls-btn a {
		font-size: 15px;
	}

	p {
		font-size: 16px;
	}

	#wrapper {
		padding: 64px 0 0;
	}

	.mobile-menu-icon {
		display: block;
	}

	.navbar-brand, .side-nav, .sidenavtoggler {
		display: none;
	}

	.device {
		align-items: center;
		flex-direction: row;
		margin-left: auto;
	}

	.main-content-footer {
		padding: 12px 20px;
		height: auto;
		z-index: 0;
	}

	#datepicker {
		margin-left: 0;
	}

	.dg {
		width: 100%;
		justify-content: space-between;
	}

	.range-box {
		width: -webkit-fill-available;
		margin-top: 22px;
	}

	.main-content {
		height: calc(100vh - 182px);
	}

	.main-content-footer {
		padding: 12px 20px;
		height: auto;
		z-index: 0;
		display: flex;
		margin-left: 0;
		flex-direction: column-reverse;
	}

	.main-content-wrapper {
		padding: 20px;
	}

	/* Force table to not be like tables anymore */
	.device-table table,
	.device-table thead,
	.device-table tbody,
	.device-table th,
	.device-table td,
	.device-table tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	.device-table thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.device-table tr {
		border-bottom: 1px solid #DFDFDF;
	}

	.device-table td {
		/* Behave  like a "row" */

		border: none;
		border-bottom: 0 !important;
		position: relative;
		padding-left: 50% !important;
		padding: 0;
		white-space: normal;
		text-align: left;

	}

	.device-table td:before {
		position: absolute;
		top: 18px;
		left: 16px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align: left;
		color: #1D1D1BBF;
	}

	/*
	Label the data
	*/
	.device-table td:before {
		content: attr(data-title);
	}

	.dnone {
		display: none !important;
	}
}
	@media(max-width:767px)
	{
		.dg .react-date-picker {margin: 0 6px 0 0;}
		.golive{width:125px;}
		.golive a span {font-size: 14px;}
	}
	@media(min-width:768px) and (max-width:1023px)
	{
		.range-box{width:30%;}
		.golive{width:134px;}
	}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2)
{  .sidenavtoggler{ margin-top:calc(100vh - 122px);} }
