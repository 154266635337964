
.date-wrapper{
border-bottom: none !important;
}
.date-wrapper > div {
  border:none;
}

.date-wrapper .react-date-picker__inputGroup{
  display: none;
}

.card-custom{
  margin-bottom: unset;
}

.card-custom .card-body{
  padding: 0px;
}

.mb-20{
  margin-bottom: 20px;
}

.btn-list{
  margin-left: unset;
}
.blink {
  animation: blink 3s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

.btn-list .btn {
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}


.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (104px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 14px 0 0 6px;
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #22ca80;
}
.range-slider__range::-webkit-slider-thumb:hover {
  background: #1abc9c;
}
.range-slider__range:active::-webkit-slider-thumb {
  background: #1abc9c;
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #22ca80;
}
.range-slider__range::-moz-range-thumb:hover {
  background: #1abc9c;
}
.range-slider__range:active::-moz-range-thumb {
  background: #1abc9c;
}

/* ::-moz-range-track {
  background: #d7dcdf;
  border: 0;
} */

/* input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
} */

@media (max-width: 575.98px) {
  .plr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

