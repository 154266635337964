.scroll-sidebar{
  overflow-y: scroll;
}

.left-sidebar{
  padding-top:60px;
}

.page-wrapper>.container-fluid{
  padding: 0px !important;
}

.page-breadcrumb{
  padding: 20px !important;
}

#main-wrapper[data-layout=vertical][data-header-position=fixed] .page-wrapper{
  padding-top:60px;
}
.topbar .top-navbar{
  min-height: 60px;
}
.navbar-collapse.collapse{
  height: 60px;
  margin-left: 0px !important;
}

.footer {
  padding: 5px 10px;
}

.topbar .top-navbar .navbar-header{
  line-height: 60px;
}

.sidebar-nav .navbar-brand {
  display: none;
}
@media (max-width: 575.98px) {
  .sidebar-nav .navbar-brand {
    display: block;
  }
  .sidebar-nav{
    padding-top: 0px;
  }
}